<template>
  <div>
    <b-card>
      <b-overlay :show="overlayShow">
        <div class="row no-print">
          <div class="col-4">
            <b-form-group title="Группа автомобилей">
              <b-form-select
                ref="groupSelector"
                v-model="group_id"
                class="plan-filter-select-input"
                :options="carsGroups"
              />
            </b-form-group>
          </div>
        </div>
        <div class="table-responsive print-land">
          <table class="mt-1 table table-bordered table-sm">
            <tr class="d-table-row border-bottom">
              <th>
                Дата
              </th>
              <th
                v-for="car in carsByGroup"
                :key="car.id"
                class="text-center"
              >
                <div class="font-weight-bolder">
                  {{ car.number }}
                </div>
                <!--
                <div class="small">
                  {{ car.vin }}
                </div>
                -->
              </th>
            </tr>
            <template v-for="{day, night, date, week_day} in tableData">
              <tr :key="date + ' 1-row' + group_id">
                <td
                  rowspan="2"
                >
                  <span class="font-weight-bolder">{{ date | dateFormat(true, false) }}</span>
                  <br>
                  <small>{{ week_day }}</small>
                </td>
                <td
                  v-for="(item, index) in day"
                  :key="index + 'day'"
                >
                  <div
                    v-if="driver_id && item.plan?.driver_id === driver_id"
                    class="text-center"
                  >
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="onPlanDeleteHandler(item.plan.id)"
                    >Удалить запись</b-button>
                  </div>
                  <div
                    v-else-if="item.plan"
                    class="text-center mt-1 mb-1"
                  >
                    ---
                  </div>
                  <div
                    v-else
                    class="text-center"
                  >
                    <b-button
                      variant="success"
                      size="sm"
                      class="mt-1 mb-1"
                      @click="onPlanCreateHandler(item.car.id, date, 1)"
                    >Записаться</b-button>
                  </div>
                </td>
              </tr>
              <tr :key="date + ' 2-row' + group_id">
                <td
                  v-for="(item, index) in night"
                  :key="index + 'night'"
                  class="bg-light"
                >
                  <div
                    v-if="driver_id && item.plan?.driver_id === driver_id"
                    class="text-center"
                  >
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="onPlanDeleteHandler(item.plan.id)"
                    >Удалить запись</b-button>
                  </div>
                  <div
                    v-else-if="item.plan"
                    class="text-center mt-1 mb-1"
                  >
                    ---
                  </div>
                  <div
                    v-else
                    class="text-center"
                  >
                    <b-button
                      variant="success"
                      size="sm"
                      class="mt-1 mb-1"
                      @click="onPlanCreateHandler(item.car.id, date, 0)"
                    >Записаться</b-button>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BOverlay,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import moment from 'moment'

const weekdayLabels = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
}

export default {
  components: {
    BCard,
    BButton,
    BOverlay,
    BFormGroup,
    BFormSelect,
  },
  data() {
    return {
      date_range: {
        start: null,
        end: null,
      },
      filterForm: {
        date_start: moment()
          .format('YYYY-MM-DD'),
        date_end: moment()
          .add(7, 'days')
          .format('YYYY-MM-DD'),
      },
      group_id: null,
      loading_plan: false,
      loading_cars: false,
      loading_cars_groups: false,
      plan: [],
      cars: [],
      cars_groups: [],
      showPlanForm: false,
      planFormData: null,
      carPlanPrintData: {
        isDay: true,
        items: [],
      },
      modalProps: {
        type: 'day',
        date: null,
        plan: null,
        car: null,
      },
    }
  },
  computed: {
    driver_id() {
      return this.$store.getters['user/driver_id']
    },
    datesList() {
      const dates = []
      const start = moment(this.date_range.start)
      const end = moment(this.date_range.end)
      while (start.isSameOrBefore(end)) {
        dates.push({
          date: start.format('YYYY-MM-DD'),
          week_day: weekdayLabels[start.isoWeekday()],
        })
        start.add(1, 'day')
      }
      return dates
    },
    carsByGroup() {
      return this.cars.filter(item => this.group_id === item.group_id)
    },
    tableData() {
      return this.datesList.map(item => ({
        ...item,
        day: this.carsByGroup.map(c => ({
          plan: this.plan.filter(p => p.date === item.date && p.car_id === c.id && p.is_day)?.pop(),
          car: c,
        })),
        night: this.carsByGroup.map(c => ({
          plan: this.plan.filter(p => p.date === item.date && p.car_id === c.id && !p.is_day)?.pop(),
          car: c,
        })),
      }))
    },
    overlayShow() {
      return !!this.loading_plan || !!this.loading_cars || !!this.loading_cars_groups
    },
    validDates() {
      return moment(this.filterForm.date_end)
        .isSameOrAfter(this.filterForm.date_start)
    },
    carsGroups() {
      return this.cars_groups.map(item => ({
        text: item.title,
        value: item.id,
      }))
    },
    modalTitle() {
      return `${this.modalProps.date} ${this.modalProps.type === 'day' ? 'дневная' : 'ночная'} смена`
    },
    selectedGroup() {
      return this.cars_groups.filter(group => group.id === this.group_id)?.pop()
    },
  },
  watch: {
    loading_cars_groups(val) {
      if (!val) {
        this.group_id = this.cars_groups.length ? this.cars_groups[0].id : null
      }
    },

  },
  mounted() {
    this.fetchCarsGroups()
    this.fetchCars()
    this.fetchPlan()
  },
  methods: {
    updateDateRange() {
      this.date_range.start = this.filterForm.date_start
      this.date_range.end = this.filterForm.date_end
    },
    async fetchPlan() {
      this.loading_plan = true
      this.updateDateRange()
      this.plan = []
      let page = 1
      let pages = 1
      for (; pages >= page;) {
        const params = {
          page,
          page_size: 100,
          filter: {
            date__gte: this.date_range.start,
            date__lte: this.date_range.end,
          },
        }
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios.get('/lk/plans', { params })
        this.plan = [...this.plan, ...data.data]
        pages = data.meta.last_page
        page += 1
      }
      this.group_id = this.$refs.groupSelector.value
      this.loading_plan = false
    },
    async fetchCars() {
      this.loading_cars = true
      this.cars = []
      let page = 1
      let pages = 1
      for (; pages >= page;) {
        const params = {
          page,
          page_size: 100,
          filter: {
            hide_crm: 0,
          },
        }
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios('/cars', { params })
        pages = data.meta.last_page
        this.cars = [...this.cars, ...data.data]
        page += 1
      }
      this.loading_cars = false
    },
    async fetchCarsGroups() {
      this.loading_cars_groups = true
      this.cars_groups = []
      let page = 1
      let pages = 1
      for (; pages >= page;) {
        const params = {
          page,
          page_size: 100,
        }
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios('/cars/groups', { params })
        pages = data.meta.last_page
        this.cars_groups = [...this.cars_groups, ...data.data]
        page += 1
      }
      this.loading_cars_groups = false
    },
    // eslint-disable-next-line camelcase
    onPlanCreateHandler(car_id, date, is_day) {
      this.$axios.post('/lk/plans', {
        car_id,
        date,
        is_day,
      }).then(() => {
        this.fetchPlan()
      }).catch(error => {
        if (error.response.status === 422) {
          this.$bvToast.toast(error.response.data.message, { variant: 'danger', title: 'Ошибка' })
        }
      })
    },
    onPlanDeleteHandler(planId) {
      this.$bvModal.msgBoxConfirm('Удалить запись?', {
        cancelTitle: 'Нет',
        okTitle: 'Да',
        centered: true,
      }).then(status => {
        if (status) {
          this.$axios.delete(`/lk/plans/${planId}`).then(() => {
            this.fetchPlan()
          })
        }
      })
    },
  },
}
</script>
